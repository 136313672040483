<template>
  <div v-if="!$_loading_state" class="dp-brew">
    <DripPodBrewHistory v-if="isCurrentHistory" :contents="historyContents" />
    <DripPodBrewHMyrecipe
      v-if="isCurrentMyrecipe"
      :contents="myrecipeContents" />
  </div>
</template>

<script>
import { SystemDialogMessage } from '@/constants/enums';

import { defineComponent, computed, ref, watch } from '@vue/composition-api';
export default defineComponent({
  components: {
    DripPodBrewHistory: () =>
      import('@/components/dripPod/brew/HistoryList.vue'),

    DripPodBrewHMyrecipe: () =>
      import('@/components/dripPod/brew/MyrecipeList.vue')
  },

  setup: (props, context) => {
    const historyContents = ref(undefined);
    const myrecipeContents = ref(undefined);
    const routeName = computed(() => context.root.$route.name);
    const isCurrentHistory = computed(
      () => routeName.value === 'DripPodBrewHistory'
    );
    const isCurrentMyrecipe = computed(
      () => routeName.value === 'DripPodBrewMyrecipe'
    );

    const fetchHistoryList = async () => {
      try {
        context.root.$_loading_start();
        const { data } = await context.root
          .$repositories('dpBrewHistory')
          .getList();
        historyContents.value = await data;
      } catch {
        window.location.href = context.root.$systemDialog(
          SystemDialogMessage.ERROR_COMMON
        );
      } finally {
        context.root.$_loading_stop();
      }
    };

    const fetchMyrecipeList = async () => {
      try {
        context.root.$_loading_start();
        const { data } = await context.root
          .$repositories('dpBrewMyrecipe')
          .getList();
        myrecipeContents.value = await data?.data;
      } catch {
        window.location.href = context.root.$systemDialog(
          SystemDialogMessage.ERROR_COMMON
        );
      } finally {
        context.root.$_loading_stop();
      }
    };

    watch(
      () => context.root.$route,
      () => {
        fetchHistoryList();
        fetchMyrecipeList();
      }
    );

    fetchHistoryList();
    fetchMyrecipeList();

    return {
      isCurrentHistory,
      isCurrentMyrecipe,
      historyContents,
      myrecipeContents
    };
  }
});
</script>

<style lang="scss" scoped>
@use '@/assets/scss/variables';
@use '@/assets/scss/mixin';

.dp-brew {
  @include mixin.dpDefaultText;
}
</style>
